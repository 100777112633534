import { Flex } from '@chakra-ui/react'
import React from 'react'

const Settings = () => {
    return (
        <Flex color='white'>Settings</Flex>

    )
}

export default Settings